import React, { useContext, useState } from "react";
import logo from "../pic/logo1.png";
import { HiOutlineSun } from "react-icons/hi";
import { BsMoonStars } from "react-icons/bs";
import { ThemeContext } from "../../contexts/theme";
import { Helmet } from 'react-helmet';

import "./header.css";

const Header = () => {
  <Helmet>
  <title>ابوالفضل قربانی </title>
  <meta name="ابوالفضل قربانی" content="ابوالفضل قربانی" />
  <meta property="og:title" content="Open Graph Title" />
  <meta property="og:description" content="Open Graph Description" />
</Helmet>
  const [{ isDark }, toggleTheme] = useContext(ThemeContext);

  window.addEventListener("scroll", function () {
    const header = document.querySelector(".header");
    header.classList.toggle("active", window.scrollY > 100);
  });

  const [Mobile, setMobile] = useState(false);
  return (
    <div className={!isDark ? undefined  : 'dark-mode'}>
      <header className="header">
        <div className="container grid">
          <div className="logo">
            <img src={logo} alt="Abolfazl Ghorbani , دکتر ابوالفضل قربانی,Dr.Abolfazl Ghorbani , ابوالفضل قربانی"/>
          </div>

          <div className="navlink">
            <ul
              className={Mobile ? "nav-links-mobile" : "link f_flex uppercase"}
              onClick={() => setMobile(false)}
            >
              <li>
                <a href="#home">home</a>
              </li>
              <li>
                <a href="#features">skills</a>
              </li>
              <li>
                <a href="#resume">resume</a>
              </li>
              {/* <li>
                <a href="#portfolio">project</a>
              </li> */}
              <li>
                <a href="#blog">publications</a>
              </li>
              {/* <li>
                <a href="#clients">interests</a>
              </li> */}
              <li>
                <a href="#contact">contact</a>
              </li>

              <span className="moon">
                <HiOutlineSun />
              </span>
              <i className="d-m ">
              <label className=" switch">
                <input  type="checkbox" onClick={toggleTheme} checked={isDark} />
                <span className="slider sw round"></span>
              </label>
              </i>
              <span className="sun">
                <BsMoonStars />
              </span>
            </ul>

            <button className="toggle" onClick={() => setMobile(!Mobile)}>
              {Mobile ? (
                <i className="fas fa-times close home-btn"></i>
              ) : (
                <i className="fas fa-bars open"></i>
              )}
            </button>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Header;
