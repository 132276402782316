import React, { useContext } from "react"
import hero from "../pic/logo2.jpg"
import Lebenslauf from "../pic/Lebenslauf.pdf"
import { CiLocationOn } from 'react-icons/ci';
import { AiFillGithub } from 'react-icons/ai';
import { FaLinkedinIn } from 'react-icons/fa';
import { SiResearchgate } from 'react-icons/si';
import { Typewriter } from "react-simple-typewriter"
import { ThemeContext } from "../../contexts/theme";
import { Helmet } from 'react-helmet';

import "./Home.css"

const Home = () => {
  <Helmet>
  <title>ابوالفضل قربانی </title>
  <meta name="ابوالفضل قربانی" content="ابوالفضل قربانی" />
  <meta property="og:title" content="Open Graph Title" />
  <meta property="og:description" content="Open Graph Description" />
</Helmet>
  const [{ isDark }, toggleTheme] = useContext(ThemeContext);


  return (
    <>
      <section className= {!isDark ? "hero": "hero dark-mode" } id='home'>
        <div className='container hero home f_flex '>
          <div className='left top'>
            <h3>WELCOME TO MY WORLD</h3>
            <h1>
              Hi, I’m <span>Abolfazl Ghorbani</span>
            </h1>
            <h2>
              a
              <span> 
                <Typewriter words={[" Full Stack Developer.", " Database Architect." ]} loop cursor cursorStyle='|' typeSpeed={70} deleteSpeed={50} delaySpeed={1000} />
              </span>
            </h2>

           
            <a ><CiLocationOn /><span className='location'>Kiel, Germany</span></a>
            <p>I am a dynamic Full Stack developer experienced in building web applications using modern technologies like PWA, SPAs, and REST services. I also specialize in database administration, including installation, performance optimization, SQL development, and data integration with various systems, backed by strong troubleshooting and documentation skills.</p>

            <div className='hero_btn d_flex'>
              <div className='col_1'>
                <h4>FIND WITH ME</h4>
                <div className='button'>
                  <a className='btn_shadow' href={Lebenslauf}  download="" rel="follow">
                    <span>DOWNLOAD RESUME</span>
                  </a>
                  <a className='btn_shadow' href="https://github.com/ab-prog1" target="_blank" >
                    <span ><AiFillGithub/></span>
                  </a>
                  <a className='btn_shadow' href="https://linkedin.com/in/abolfazl-ghorbani" target="_blank" >
                  <span><FaLinkedinIn/></span>
                  </a>
                  <a className='btn_shadow' href="https://researchgate.net/profile/Abolfazl-Ghorbani-2" target="_blank" >
                  <span><SiResearchgate/></span>
                  </a>
                </div>
              </div>
              </div>
          </div>
          <div className="col-lg-4 righ">
            <div className="div-img-bg">
              <div className="about-img">
                <img src={hero} className="responsive" alt="Abolfazl Ghorbani , دکتر ابوالفضل قربانی,Dr.Abolfazl Ghorbani , ابوالفضل قربانی"/>
              </div>
            </div>
          </div>
          </div>
      </section>
    </>
  )
}

export default Home
