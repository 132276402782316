import { FaBriefcase , FaGraduationCap } from "react-icons/fa";



const ResumeApi = [
  {
    id: 1,
    category: "education",
    year: "University of Isfahan, Iran. (2018 - 2022)",
    title: "PhD, Software Engineering",
    desc: "THESIS: Improvement and development of semantic interoperability (Semantic Reasoning) based on ontology using Type-2 Fuzzy Set.",
    rate: <FaGraduationCap  size={22} />,
  },
  {
    id: 2,
    category: "education",
    year: "Azad university North Tehran Branch, Tehran, Iran (2016 - 2018) ",
    title: "Master of Science, Software Engineering",
    desc: "THESIS: The Detection of Taxpayers with False Invoices using Data Mining Techniques.",
    rate: <FaGraduationCap  size={22} />,
  },
  {
    id: 3,
    category: "education",
    year: "Azad university, khomein Branch, Khomein, Iran (2008 - 2010) ",
    title: "Bachelor of Science, Software Engineering",
    desc: "THESIS: The Detection of Taxpayers with False Invoices using Data Mining Techniques.",
    rate: <FaGraduationCap  size={22} />,
  },
  {
    id: 4,
    category: "experience",
    year: "Database Architect - Full stack Developer  (Jun 2023 - Present) ",
    title: "Kiel University",
    desc: "JavaScript (React.js, Next.js), PHP (Laravel), Python (NumPy, pandas), Microsoft SQL Server, MySQL, PostgreSQL",
    rate: <FaBriefcase  size={22} />,
  },
  {
    id: 4,
    category: "experience",
    year: "Full stack Developer -Database Developer Sep 2013 - Jul 2020",
    title: "Tax Organization",
    desc: "JavaScript (React.js, Next.js), PHP (Laravel), Python (NumPy, pandas), MySQL, PostgreSQL",
    rate: <FaBriefcase  size={22} />,
  },
  {
    id: 6,
    category: "experience",
    year: "Database Manager Sep 2010 - Sep 2013 ",
    title: "Iran Nara Co",
    desc: "Python (NumPy, pandas), Microsoft SQL Server",
    rate: <FaBriefcase  size={22} />,
  },
  {
    id: 6,
    category: "experience",
    year: "Database Administration Support Officer Jul 2008 - Sep 2010",
    title: "DPI Co",
    desc: "Python , Microsoft SQL Server",
    rate: <FaBriefcase  size={22} />,
  },
  {
    id: 6,
    category: "experience",
    year: "Database Assistant",
    title: "set construction company",
    desc: " Microsoft SQL Server",
    rate: <FaBriefcase  size={22} />,
  },
  {
    id: 6,
    category: "experience",
    year: "System Analyst and IT Expert (2006 - 2008)",
    title: "Gold iran company",
    desc: " Technical Support, install hardware and software, troubleshooting in the fields of hardware and software, networks and communication systems, Support for end devices in a networked environment.",
    rate: <FaBriefcase  size={22} />,
  },
]

export default ResumeApi
