import { createContext, useState, useEffect } from "react";


export const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  const [isDark, setIsDark] = useState(true);


  const toggleTheme = () => {
      localStorage.setItem("isDark", JSON.stringify(!isDark));
    setIsDark(!isDark);
  };
  

  useEffect(() => {
    const isDark = localStorage.getItem("isDark") === "true";
    setIsDark(isDark);
  }, []);

  return (
    <ThemeContext.Provider value={[{  isDark }, toggleTheme]}>
      {children}
    </ThemeContext.Provider>
  );
};
