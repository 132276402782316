import React from "react";
import "../Portfolio/Portfolio.css";
import "../Blog/Blog.css";
import Card from "./Card";
import BlogApi from "./BlogApi";

const Blog = () => {
  return (
    <>
      <section className='Portfolio Blog' id='blog'>
        <div className='container top'>
          <div className='heading text-center'>
            <h4>VISIT MY PUBLICATIONS AND KEEP YOUR FEEDBACK</h4>
            <h1>MY PUBLICATIONS</h1>
          </div>

          <div className='grid'>
            {BlogApi.map((value, index) => (
              <Card 
                key={index} 
                image={value.image}
                date={value.date}
                title_one={value.title_one}
                title_two={value.title_two}
                title_three={value.title_three}
                desc_one={value.desc_one}
                desc_two={value.desc_two}
                desc_three={value.desc_three}
                link={value.link}
                publicher={value.publicher}
              />
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Blog;
