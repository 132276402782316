import React, { useContext } from "react"
import Header from "./component/Head/Header"
import Features from "./component/Features/Features"
import Home from "./component/Hero/Home"
// import Portfolio from "./component/Portfolio/Portfolio"
import Resume from "./component/Resume/Resume"
// import Testimonial from "./component/Testimonial/Testimonial"
import Blog from "./component/Blog/Blog"
import Contact from "./component/Contact/Contact"
import Footer from "./component/Footer"
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ThemeContext } from "./contexts/theme";
import { Helmet } from 'react-helmet';

import "./App.css"



const App = () => {
  <Helmet>
  <title>ابوالفضل قربانی </title>
  <meta name="ابوالفضل قربانی" content="ابوالفضل قربانی" />
  <meta property="og:title" content="Open Graph Title" />
  <meta property="og:description" content="Open Graph Description" />
</Helmet>
  const [{ isDark }] = useContext(ThemeContext);

  return (
    <div className= {!isDark ?  undefined  : 'dark-mode'} >
      <Header />
      <Home />
      <Features />
      <Resume />
      {/* <Portfolio /> */}
      <Blog />
      {/* <Testimonial /> */}
      <Contact />
      <Footer />
      <ToastContainer />  
      </div>
  )
}

export default App
